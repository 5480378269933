import React from "react";
import { useRecoilState } from "recoil";
import { ModalContentState, ModalState } from "../atom/modal";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/css";

const AboutModal = (props) => {
  const [, setIsModalOpen] = useRecoilState(ModalState);
  const [modalContent] = useRecoilState(ModalContentState);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="modal-wrapper">
      <div className="modal-outer-container">
        <button onClick={handleCloseModal} className="close_button">
          ✖️
        </button>
        <div className="modal-inner-container">
          <p className="about-title">{modalContent.title}</p>
          <Splide
            aria-label="JDO-滋賀びわこ彦根校 会場写真"
            options={{
              autoplay: true,
              interval: 2000,
            }}
          >
            {modalContent.images.map((image) => {
              return (
                <SplideSlide>
                  <img
                    className="slide-img"
                    src={image}
                    alt={modalContent.title}
                  />
                </SplideSlide>
              );
            })}
          </Splide>
          <style jsx>{`
            .slide-img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          `}</style>
        </div>
      </div>
    </div>
  );
};

export default AboutModal;
