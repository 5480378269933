import React from "react";
import "./Hero.css";
import drone_image from "../../assets/drone.png";
import hero_image_back from "../../assets/hero_image_back.png";
import Certification from "../../assets/certification.png";
import NumberCounter from "number-counter";

// To create animation
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const AnimatedText = (props) => {
  let text = props.text;
  let letters = text.split("");
  let content = letters.map((letter) => {
    let delay = Math.floor(Math.random() * 1000 + 1);
    return (
      <span
        className="hero-letter-box"
        style={{ animationDelay: `${delay}ms` }}
      >
        {letter}
      </span>
    );
  });
  return <p className="hero-animated-text">{content}</p>;
};

const Hero = () => {
  const transition = { type: "spring", duration: 3 };
  const mobile = window.innerWidth <= 768 ? true : false;

  return (
    <div className="hero" id="home">
      <div className="blur hero-blur"></div>

      <div className="left-h">
        <div className="the-best-ad">
          <motion.div
            initial={{ left: mobile ? "115px" : "150px" }}
            whileInView={{ left: "8px" }}
            transition={{ ...transition, type: "tween" }}
          ></motion.div>
          <span>the best DRONE School</span>
        </div>

        {/* Hero Heading */}
        <div className="hero-text">
          <div>
            <span className="stroke-text">JDO </span>
            <span>- </span>
          </div>

          <div>
            <span>滋賀びわこ彦根校</span>
          </div>
          {mobile ? (
            <p className="hero-animated-text">
              ¥132,000で ドローンライセンスを取得
            </p>
          ) : (
            <AnimatedText text="¥132,000で ドローンライセンスを取得" />
          )}
        </div>

        {/* figures */}

        <div className="figures">
          <div>
            <div className="number_text_container">
              <span>
                {mobile ? (
                  145
                ) : (
                  <NumberCounter end={145} start={100} delay="4" />
                )}
              </span>
              <span className="suffix">人</span>
            </div>
            <span>受講者数</span>
          </div>

          <div>
            <div className="number_text_container">
              <span>132000</span>
              <span className="suffix">円</span>
            </div>
            <span>価格</span>
          </div>

          <div>
            <div className="number_text_container">
              <span>3</span>
              <span className="suffix">日</span>
            </div>
            <span>受講期間</span>
          </div>
        </div>

        {/* hero buttons */}

        <div className="hero-buttons">
          <Link to="/contact">申し込み</Link>
          {/* <Link to="/about">詳しく</Link> */}
        </div>
      </div>

      <div className="right-h">
        <img src={drone_image} alt="" className="hero-image" />
        <motion.img
          src={hero_image_back}
          className="hero-image-back"
          initial={{ right: "15rem", top: "10rem" }}
          whileInView={{ right: "25rem", top: "10rem" }}
          transition={transition}
          alt="ドローン 滋賀"
        />

        {/* certification */}

        <motion.div
          className="certification"
          initial={{ right: "30rem" }}
          whileInView={{ right: "40rem" }}
          transition={transition}
          alt="ドローン 滋賀"
        >
          <div>
            <span>国土交通省 </span>
            <span>登録講習機関</span>
          </div>
          <img src={Certification} alt="JDOドローン 認定スクール 滋賀" />
        </motion.div>
      </div>
    </div>
  );
};

export default Hero;
