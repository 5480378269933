import "../App.css";
import Calendar from "../components/Calendar/Calendar";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Hero from "../components/Hero/Hero";
import Join from "../components/Join/Join";
import PlanSection from "../components/Plans/Plans";
import Programs from "../components/Programs/Programs";
import Reasons from "../components/Reasons/Reasons";
import Testimonials from "../components/Testimonials/Testimonials";
import ScrollToTop from "../components/ScrollToTop";

const Home = () => {
  return (
    <div className="App">
      <Header />
      <ScrollToTop />
      <div className="main">
        <Hero />
        <Programs />
        <Reasons />
        <PlanSection />
        <Testimonials />
        <Calendar />
        <Join />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
