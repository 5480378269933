import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="Footer-container">
      <div className="footer">
        <div className="siga-container">
          <div className="footer-r">
            <p>関中建設技術センター(JDO滋賀びわこ彦根校) TEL:0749-23-8835</p>
            <p>〒522-0022 滋賀県彦根市里根町201-1 滋賀県彦根市里根町201番地1</p>
            <p>
              国土交通省 登録講習機関 | 日本ドローン機構株式会社 |
              登録講習機関コード: 0319
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
