import hikone1 from "../assets/venue_hikone1.jpg";
import hikone2 from "../assets/venue_hikone2.jpg";
import hikone3 from "../assets/venue_hikone3.jpg";
import hikone4 from "../assets/venue_hikone4.jpg";
import hikone5 from "../assets/venue_hikone5.jpg";
import hikone6 from "../assets/venue_hikone6.jpg";
import biwako1 from "../assets/venue_biwako1.jpg";
import biwako2 from "../assets/venue_biwako2.jpg";
import biwako3 from "../assets/venue_biwako3.jpg";
import biwako5 from "../assets/venue_biwako5.jpg";
import biwako6 from "../assets/venue_biwako6.jpg";
import biwako7 from "../assets/venue_biwako7.jpg";
import biwako8 from "../assets/venue_biwako8.jpg";
import rain1 from "../assets/venue_rain1.jpg";
import rain2 from "../assets/venue_rain2.jpg";
import rain3 from "../assets/venue_rain3.jpg";

export const VENUE_DATA = [
  {
    headImage: [hikone1, hikone2, hikone3, hikone4, hikone5, hikone6],
    heading: "ドローン実技会場(彦根)",
    address: "住所: 滋賀県彦根市里根町200番地1",
  },
  {
    headImage: [biwako1, biwako2, biwako3, biwako5, biwako6, biwako7, biwako8],
    heading: "ドローン実技会場(びわこ)",
    address: "住所: 滋賀県彦根市松原町1085番14",
  },
  {
    headImage: [rain1, rain2, rain3],
    heading: "ドローン実技会場(雨天練習場)",
    address: "住所: 滋賀県彦根市小泉町640番地",
  },
];
