import React from "react";
import "./index.css";
import { RecoilRoot } from "recoil";
import ReactDOM from "react-dom";
import RouterConfig from "./RouterConfig";

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <RouterConfig />
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById("root")
);
