import React from "react";
import "./Programs.css";
import { JDO_DATA } from "../../data/JDO_Data";

const Programs = () => {
  return (
    <div className="Programs" id="programs">
      <div className="programs-header">
        <span className="stroke-text">JDOドローンスクールの</span>
        <span>ここが凄い</span>
        <span className="stroke-text"></span>
      </div>

      <div className="program-categories">
        {JDO_DATA.map((point) => (
          <div className="category">
            <span>{point.heading}</span>
            <span>{point.details}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Programs;
