import React from "react";
import "./Header.css";
import { useState } from "react";

const Header = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [navColor, updateNavbar] = useState(false);

  function scrollHandler() {
    if (window.scrollY >= 10) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);

  return (
    <nav className={navColor ? "header" : "navigation"}>
      <a href="/" className="brand-name">
        滋賀びわこ彦根校
      </a>
      <button
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="white"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <div
        className={
          isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }
      >
        <ul>
          <li>
            <a href="/">ホーム</a>
          </li>

          <li>
            <a href="/jdo">JDOについて</a>
          </li>

          <li>
            <a href="/about">滋賀びわこ彦根校</a>
          </li>

          <li>
            <a href="/plan">コース紹介</a>
          </li>

          {/* <li>
            <Link to="calendars">カレンダー</Link>
          </li> */}

          <li>
            <a href="/contact">お申し込み</a>
          </li>
          <li>
            <a href="tel:+81-0749-23-8835">☎️ 0749-23-8835 </a>
          </li>
          <li>
            <a href="tel:+81-90-8888-2323">📱 090-8888-2323</a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Header;
