export const JDO_DATA = [
  {
    heading: "建設関係者様 受講実績多数",
    details:
      "公共機関・大手企業からの撮影・測量実績、行政機関との点検業務、大学機関との生態調査など数多くの実践経験を有した現役のインストラクターが講師となり、仕事に活かせる技術が身につきます。",
  },
  {
    heading: "たった3日! 132,000円!",
    details:
      "ドローンに全く触れたことない初心者の方でも、「国交省が規定した飛行に必要な操縦技量基準以上」のスキルをリーズナブルに習得できます。",
  },
  {
    heading: "国土交通省航空局 登録！",
    details:
      "弊社のドローンスクールは,国土交通省航空局の登録講習機関として登録を受けたスクールであるため、安心してご受講いただけます。",
  },
];
