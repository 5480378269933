export const TESTIMONIAL_DATA = [
  {
    // image: image1,
    review:
      "最新の機種で実技講習ができてよかった。どのドローンを購入するかの参考になった。",
    name: "T.A 様",
    status: "ベーシック+アドバンスコース",
  },
  {
    // image: image2,
    review: "広大なびわこを背景に、実技できてよかった。",
    name: "S.R 様",
    status: "ベーシックコース",
  },
  {
    // image: image3,
    review:
      "初心者でも、ドローンを飛ばせる様になった。 登録、申請もよく理解できた。",
    name: "Y.H 様",
    status: "ベーシック+アドバンスコース",
  },
];
