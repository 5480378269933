import React from "react";
import AboutSection from "../components/About/AboutSection";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Join from "../components/Join/Join";
import ScrollToTop from "../components/ScrollToTop";

const About = () => {
  return (
    <div className="App">
      <Header />
      <ScrollToTop />
      <div className="main">
        <AboutSection />
        <Join />
      </div>
      <Footer />
    </div>
  );
};

export default About;
