import React from "react";
import Iframe from "react-iframe";
import Header from "../components/Header/Header";
import ScrollToTop from "../components/ScrollToTop";
import JDOFooter from "../components/Footer/jdoFooter";

const JDO = () => {
  return (
    <div className="App">
      <Header />
      <ScrollToTop />
      <div>
        <Iframe
          id="jdo"
          url="https://jpndo.com/"
          position="absolute"
          width="100%"
          height="80%"
        />
      </div>
      <JDOFooter />
    </div>
  );
};

export default JDO;
