import { atom } from "recoil";

const ModalState = atom({
  key: "ModalStateAtom",
  default: false,
});

const ModalContentState = atom({
  key: "ModalContentStateAtom",
  default: { title: "", images: [""] },
});

export { ModalState, ModalContentState };
