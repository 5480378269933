import React from "react";
import "./Footer.css";

const JDOFooter = () => {
  return (
    <div className="jdo-footer">
      <button className="home-button">
        <a href="/">ホームに戻る</a>
      </button>
    </div>
  );
};

export default JDOFooter;
