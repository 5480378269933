import React, { useState } from "react";
import "./Contact.css";

import emailJs from "@emailjs/browser";

const ContactSection = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [course, setCourse] = useState("二等無人航空機操縦士 初心者コース");
  const [text, setText] = useState("");

  const serviceID = "service_rfc67d3";
  const templateID = "template_963a9qk";
  const key = "2LLupqkFYcX0Rh8ZN";

  const sendMail = () => {
    const template_param = {
      name: name,
      email: email,
      phone: phone,
      course: course,
      text: text,
    };

    if (name !== "" && email !== "" && phone !== "" && course !== "") {
      emailJs
        .send(serviceID, templateID, template_param, key)
        .then((res) => {
          window.alert("お申し込み内容を送信致しました。");
          return res;
        })
        .then((data) => {
          console.log("data from response", JSON.stringify(data));

          if (data.status < 299) {
            setName("");
            setEmail("");
            setPhone("");
            setCourse("");
            setText("");
          }
        })
        .catch((err) => {
          console.log("ERROR \n" + JSON.stringify(err));
        });
    } else {
      window.alert("必須項目を記入してください");
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    sendMail();
  };

  return (
    <div className="contact" id="contact">
      <div className="contact-left-h">
        {/* the best ad */}

        {/* Hero Heading */}
        <div className="hero-text">
          <div>
            <span className="stroke-text">JDO </span>
            <span>- </span>
          </div>

          <div>
            <span>滋賀びわこ彦根校</span>
          </div>
        </div>

        {/* figures */}

        <div className="contact-figures">
          <div>
            <span>お申し込み</span>
            <span>※お申し込みは右側のフォームをご入力ください</span>
          </div>
        </div>
      </div>

      <div className="contact-right-h">
        <div id="content">
          <h1>Contact</h1>

          <div className="Form">
            <div className="Form-Item">
              <p className="Form-Item-Label">
                <span className="Form-Item-Label-Required">必須</span>お名前
              </p>
              <input
                className="Form-Item-Input"
                type="text"
                name="username"
                id="username"
                required
                placeholder="田中太郎"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="Form-Item">
              <p className="Form-Item-Label">
                <span className="Form-Item-Label-Required">必須</span>
                メールアドレス
              </p>
              <input
                className="Form-Item-Input"
                placeholder="例）example@gmail.com"
                type="email"
                name="usermail"
                id="usermail"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="Form-Item">
              <p className="Form-Item-Label">
                <span className="Form-Item-Label-Required">必須</span>電話番号
              </p>
              <input
                type="text"
                name="phone"
                id="phone"
                className="Form-Item-Input"
                placeholder="例）000-0000-0000"
                required
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="Form-Item">
              <p className="Form-Item-Label">
                <span className="Form-Item-Label-Required">必須</span>
                ご希望コース
              </p>
              <select
                name="course"
                id="course"
                className="Form-Item-Input"
                required
                value={course}
                onChange={(e) => setCourse(e.target.value)}
              >
                <option value="一等無人航空機操縦士初学者コース">
                  一等無人航空機操縦士初学者コース
                </option>
                <option value="一等無人航空機操縦士経験者コース">
                  一等無人航空機操縦士経験者コース
                </option>
                <option value="二等無人航空機操縦士初学者コース + 学科対策講座セット">
                  二等無人航空機操縦士初学者コース + 学科対策講座セット
                </option>
                <option value="二等無人航空機操縦士経験者コース + 学科対策講座セット">
                  二等無人航空機操縦士経験者コース + 学科対策講座セット
                </option>
                <option value="無人航空機操縦士 ベーシックコース">
                  無人航空機操縦士 ベーシックコース
                </option>
                <option value="無人航空機操縦士 ベーシック + アドバンスコース">
                  無人航空機操縦士 ベーシック + アドバンスコース
                </option>
                <option value="無人航空機操縦士 インストラクターコース">
                  無人航空機操縦士 インストラクターコース
                </option>
                <option value="空撮技術コース">空撮技術コース</option>
                <option value="映像編集ベーシックコース">
                  映像編集ベーシックコース
                </option>
                <option value="映像編集アドバンスコース">
                  映像編集アドバンスコース
                </option>
              </select>
            </div>
            <div className="Form-Item">
              <p className="Form-Item-Label">お問い合わせ内容</p>
              <textarea
                type="text"
                name="contact-text"
                id="contact-text"
                className="Form-Item-Textarea"
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
            </div>
            <div className="btn-container">
              <div className="btn-center">
                <button
                  className="contact-btn"
                  type="submit"
                  onClick={handleClick}
                >
                  <svg
                    className="border"
                    viewBox="0 0 180 60"
                    height="60px"
                    width="180px"
                  >
                    <polyline
                      className="bg-line"
                      points="179,1 179,59 1,59 1,1 179,1"
                    ></polyline>
                    <polyline
                      className="hl-line"
                      points="179,1 179,59 1,59 1,1 179,1"
                    ></polyline>
                  </svg>
                  <span>送信する</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
