import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Join from "../components/Join/Join";
import PlanSection from "../components/Plans/PlanSection";
import ScrollToTop from "../components/ScrollToTop";

const Plan = () => {
  return (
    <div className="App">
      <Header />
      <ScrollToTop />
      <div className="main">
        <PlanSection />
        <Join />
      </div>
      <Footer />
    </div>
  );
};

export default Plan;
