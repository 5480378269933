import React from "react";
import "./Join.css";

const Join = () => {
  return (
    <div className="Join">
      <div className="left-j">
        <hr />

        <div>
          <a href="https://www.mlit.go.jp/">国土交通省</a>
          <a href="https://www.mlit.go.jp/koku/koku_tk10_000003.html">
            航空無人飛行機の飛行ルール
          </a>

          <a href="https://www.mlit.go.jp/koku/drone/">
            無人航空機のポータルサイト
          </a>
          <a href="https://www.mlit.go.jp/koku/koku_tk10_000003.html">
            無人飛行機(ドローン・ラジコン機等)の飛行ルール
          </a>
          <a href="https://www.mlit.go.jp/koku/koku_ua_registration.html">
            無人飛行機の登録制度
          </a>
          <a href="https://www.mlit.go.jp/koku/drone/">
            無人航空機登録ポータルサイト
          </a>
          <a href="https://www.ossportal.dips.mlit.go.jp/portal/top/">
            DIPS ドローン登録基盤システム
          </a>
          <a href="https://jpndo.com/">日本ドローン機構</a>
        </div>
      </div>
    </div>
  );
};

export default Join;
