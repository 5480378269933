import React from "react";
import "./Reasons.css";
import image1 from "../../assets/image1.png";
import image2 from "../../assets/image2.png";
import image3 from "../../assets/image3.png";
import image4 from "../../assets/image4.png";
import tick from "../../assets/tick.png";

const Reasons = () => {
  return (
    <div className="Reasons" id="reasons">
      <div className="left-r">
        <img src={image1} alt="ドローン 滋賀" />
        <img src={image2} alt="ドローン 滋賀" />
        <img src={image3} alt="ドローン 滋賀" />
        <img src={image4} alt="ドローン 滋賀" />
      </div>

      <div className="right-r">
        <span>選ばれる理由</span>

        <div>
          <span className="stroke-text">JDO </span>
          <span>滋賀びわこ彦根校</span>
        </div>

        <div>
          <div>
            <img src="" alt="" />
            <span></span>
          </div>
        </div>

        <div className="details-r">
          <div>
            <img src={tick} alt="ドローン 滋賀" />
            <span>初心者の方~仕事でドローンを使う方まで分かりやすい講義</span>
          </div>

          <div>
            <img src={tick} alt="ドローン 滋賀" />
            <span>学科はオンライン受講が可能</span>
          </div>

          <div>
            <img src={tick} alt="ドローン 滋賀" />
            <span>少人数で受講可能</span>
          </div>

          <div>
            <img src={tick} alt="ドローン 滋賀" />
            <span>飛行実技は、日曜日・休日等のみの分割受講が可能</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reasons;
