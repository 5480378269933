import React from "react";
import "./Plans.css";
import {
  PLAN_DATA,
  JDO_PLAN_DATA_NATIONAL,
  JDO_PLAN_DATA_PRIVATE,
} from "../../data/PLAN_Data";
import whiteTick from "../../assets/whiteTick.png";

const PlanSection = () => {
  return (
    <div className="plans-container">
      {/* JDO 滋賀びわこ校 料金プランカード  */}
      <div className="programs-header">
        <span className="stroke-text">JDO 滋賀びわこ校</span>
        <span>コース紹介</span>
      </div>
      <div className="plans" id="plans">
        {PLAN_DATA.map((plan, i) => (
          <div className="plan" key={i}>
            {plan.icon}
            <span>{plan.name}</span>
            <span>¥ {plan.price}</span>

            <div className="features">
              {plan.features.map((feature, i) => (
                <div className="feature">
                  <img src={whiteTick} alt="ドローン 滋賀" />
                  <span key={i}>{feature}</span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <a href="/contact" className="btn btn-svg">
        <svg>
          <rect x="2" y="2" rx="0" fill="none" width="200" height="50" />
        </svg>
        <span>申し込みはこちらから</span>
      </a>

      {/* JDO 本部 国家資格コース料金プランカード */}
      <div className="programs-header">
        <span className="stroke-text">JDO ドローンスクール</span>
        <span>国家資格コース紹介</span>
      </div>
      <div className="national_plans" id="plans">
        {JDO_PLAN_DATA_NATIONAL.map((plan, i) => (
          <div className="national_plan" key={i}>
            <span>{plan.head}</span>
            <span>{plan.name}</span>
            <span>{plan.price}</span>
            <span>{plan.note}</span>
            <span>{plan.description}</span>
          </div>
        ))}
      </div>
      <a href="/contact" className="btn btn-svg">
        <svg>
          <rect x="2" y="2" rx="0" fill="none" width="200" height="50" />
        </svg>
        <span>申し込みはこちらから</span>
      </a>

      {/* JDO 本部 民間資格コース料金プランカード */}
      <div className="programs-header">
        <span className="stroke-text">JDO ドローンスクール</span>
        <span>民間資格コース紹介</span>
      </div>
      <div className="private_plans" id="plans">
        {JDO_PLAN_DATA_PRIVATE.map((plan, i) => (
          <div className="private_plan" key={i}>
            <span>{plan.name}</span>
            <span>¥ {plan.price}</span>

            <div className="features">
              {plan.features.map((feature, i) => (
                <div className="feature">
                  <img src={whiteTick} alt="ドローン 滋賀" />
                  <span key={i}>{feature}</span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <a href="/contact" className="btn btn-svg">
        <svg>
          <rect x="2" y="2" rx="0" fill="none" width="200" height="50" />
        </svg>
        <span>申し込みはこちらから</span>
      </a>
    </div>
  );
};

export default PlanSection;
