import React, { useEffect, useState } from "react";
import "../Calendar/Calendar.css";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import jaLocale from "@fullcalendar/core/locales/ja";

const Calendar = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const res = await fetch(
        "https://script.google.com/macros/s/AKfycbwBM73kIJOpS4TXHYQBWZnmZ83S0BQmSYscTbMhOpscUxmYRVAjxEwdRZEvhbEO00pXWQ/exec"
      );
      const data = await res.json();
      if (data !== undefined) {
        setEvents(data);
      }
    }
    fetchData();
  }, []);

  return (
    <div className="calendars-container">
      <div className="programs-header" style={{ gap: "2rem" }}>
        <span className="stroke-text">JDO ドローンスクール</span>
        <span>カレンダー</span>
      </div>

      {/* calendars */}

      <div className="calendars" id="calendars">
        <FullCalendar
          contentHeight="auto"
          themeSystem="slate"
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          locales={[jaLocale]}
          locale="ja"
          events={events}
        />
      </div>
    </div>
  );
};

export default Calendar;
