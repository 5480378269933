import React from "react";
import "./About.css";
import { VENUE_DATA } from "../../data/VENUE_Data";
import RightArrow from "../../assets/rightArrow.png";
import AboutDialog from "./AboutDialog";
import { useRecoilState } from "recoil";
import { ModalContentState, ModalState } from "../atom/modal";
import tick from "../../assets/tick_drone.png";
import image1 from "../../assets/drone_image1.jpg";
import image2 from "../../assets/drone_image2.png";
import image3 from "../../assets/drone_image3.png";
import image4 from "../../assets/drone_image4.png";

const AboutSection = () => {
  const [isModalOpen, setIsModalOpen] = useRecoilState(ModalState);
  const [modalContent, setModalContent] = useRecoilState(ModalContentState);

  const handleOpenModal = (title, images) => {
    console.log(title);
    console.log(images);
    setModalContent({ title: title, images: images });
    console.log(modalContent);
    setIsModalOpen(true);
  };
  return (
    <div className="about-container">
      {/* header */}
      <div className="about-header">
        <span className="stroke-text">JDO 滋賀びわこ彦根校</span>
      </div>

      <div className="about-title-container">
        <span>晴天・雨天 対応の充実した環境設備！</span>

        <div>
          <span className="stroke-text">滋賀びわこ彦根校 </span>
          <span>会場風景</span>
        </div>
      </div>

      <div className="about-categories">
        {VENUE_DATA.map((point) => (
          <div
            className="about-category"
            onClick={() => handleOpenModal(point.heading, point.headImage)}
          >
            <img src={point.headImage[0]} alt="ドローン 滋賀" />
            <span>{point.heading}</span>
            <span>{point.address}</span>
            <div className="about-more">
              <span>もっと見る</span>
              <img src={RightArrow} alt="ドローン 滋賀" />
            </div>
          </div>
        ))}
      </div>
      {isModalOpen && <AboutDialog />}
      <div className="drone-garally-container">
        <div className="left-r">
          <img src={image1} alt="ドローン 滋賀" />
          <img src={image2} alt="ドローン 滋賀" />
          <img src={image3} alt="ドローン 滋賀" />
          <img src={image4} alt="ドローン 滋賀" />
        </div>
        <div className="about-title-container">
          <span>最新の主要ドローン機で講習可能！</span>

          <div>
            <span className="stroke-text">滋賀びわこ彦根校 </span>
            <span>使用機材</span>
          </div>

          <div className="details-r">
            <div>
              <img src={tick} alt="DJI MAVIC3 " />
              <span className="dji-link">
                <a href="https://www.dji.com/jp/mavic-3">DJI MAVIC3 1台</a>
              </span>
            </div>

            <div>
              <img src={tick} alt="DJI MAVIC3 classic" />
              <span className="dji-link">
                <a href="https://www.dji.com/jp/mavic-3-classic">
                  DJI MAVIC3 classic 2台
                </a>
              </span>
            </div>

            <div>
              <img src={tick} alt="DJI AIR2S" />
              <span className="dji-link">
                <a href="https://www.dji.com/jp/air-2s">DJI AIR2S 1台</a>
              </span>
            </div>

            <div>
              <img src={tick} alt="DJI MINI3" />
              <span className="dji-link">
                <a href="https://www.dji.com/jp/mini-3">DJI MINI3 4台</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
