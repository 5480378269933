import React from "react";
import "./Plans.css";
import { PLAN_DATA } from "../../data/PLAN_Data";
import whiteTick from "../../assets/whiteTick.png";

const Plans = () => {
  return (
    <div className="plans-container">
      <div className="programs-header" style={{ gap: "2rem" }}>
        <span className="stroke-text">JDO 滋賀びわこ校</span>
        <span>コース紹介</span>
      </div>

      {/* plans card */}

      <div className="plans" id="plans">
        {PLAN_DATA.map((plan, i) => (
          <div className="plan" key={i}>
            {plan.icon}
            <span>{plan.name}</span>
            <span>¥ {plan.price}</span>

            <div className="features">
              {plan.features.map((feature, i) => (
                <div className="feature">
                  <img src={whiteTick} alt="ドローン 滋賀" />
                  <span key={i}>{feature}</span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <a href="/contact" className="btn btn-svg">
        <svg>
          <rect x="2" y="2" rx="0" fill="none" width="200" height="50" />
        </svg>
        <span>申し込みはこちらから</span>
      </a>
    </div>
  );
};

export default Plans;
